<template>
  <i-dialog name="callback-modal">
    <p slot="title">ЗАКАЗАТЬ ЗВОНОК</p>
    <p class="t-green_ fw-b">
      Укажите Ваш номер телефона, мы перезвоним в течение дня
    </p>
    <el-form
      ref="callbackForm"
      :model="form"
      :rules="rules"
      label-position="left"
      class="callback-form i-components"
      @submit.native.prevent
      @keyup.enter.native="onClick"
    >
      <el-form-item prop="phone">
        <el-input
          v-model="form.phone"
          v-mask="'+7 (###) ###-##-##'"
          placeholder="Введите номер телефона"
          prefix-icon="user-icon"
          autocomplete="nope"
          class="phone"
        />
      </el-form-item>
      <el-row type="flex" justify="end" class="mt-50 f-wrap">
        <div class="w-m-100 mt-m-20">
          <i-button
            :loading="loading"
            :disabled="loading"
            text="Отправить"
            width="250"
            @click="onClick"
          />
        </div>
      </el-row>
    </el-form>
  </i-dialog>
</template>

<script>
import { mapMutations } from 'vuex'
import IDialog from '@/components/Ui/Dialog'
import IButton from '@/components/Ui/Button'
import { callbackFormRules } from './constants'

export default {
  name: 'CallbackModal',
  components: { IDialog, IButton },
  data() {
    return {
      rules: callbackFormRules,
      form: {
        phone: ''
      },
      loading: false
    }
  },
  methods: {
    ...mapMutations({ setDialog: 'popups/setDialog' }),
    onClick() {
      this.$refs.callbackForm.validate(valid => {
        if (valid) {
          this.setDialog({ name: 'callback-modal' })
        }
      })
    }
  }
}
</script>

<style lang="sass">
.el-dialog.callback-modal
  max-width: 700px
  .t-green_
    color: #14A14D
  .callback-form
    .phone input
      padding-left: 70px
    input
      background: #E8E8E8
</style>
