export const blocks = [
  [
    {
      bold: 'Доступность.',
      text:
        'Тендеры больших экспортеров на объемные перевозки доступны к торгам для всех в одном месте.'
    },
    {
      bold: 'Равноправие.',
      text:
        'Условия тендера одинаковы для всех, в торгах участвуют обезличенные номера договора и автоматически побеждает лучшая ставка.'
    }
  ],
  [
    {
      bold: 'Открытость.',
      text:
        'Все участники сервиса могут ознакомиться с любыми торгами и их условиями.'
    },
    {
      bold: 'Прозрачность.',
      text:
        'Все участники тендера могут видеть ход торгов и условия, на которых с победителем заключен договор.'
    }
  ]
]

export const brands = ['cargill']
