<template>
  <bg-image-wrapper src="bg-description.jpeg" title="О НАШЕМ СЕРВИСЕ" flip>
    <section slot="body" class="descr-body fw-l t-black-light fs-18">
      <p>
        Наш сервис &mdash; это площадка для организации прозрачных торгов на
        перевозку сельскохозяйственных грузов автотранспортом. Основное
        направление перевозок &mdash; экспортные порты, основные заказчики
        &mdash; крупные экспортеры.
      </p>
      <p>
        Чтобы участвовать в торгах, вы должны иметь действующий договор
        (аккредитацию) на перевозку/экспедирование с одним из заказчиков,
        использующих наш сервис.
      </p>
      <p class="t-green_">
        <b>Наш сервис абсолютно бесплатный для всех пользователей!</b>
      </p>
    </section>
    <section class="container descr-bottom">
      <el-row class="mt-70" type="flex" justify="space-between">
        <el-col :xs="24" :sm="17">
          <h2 class="t-blackDark mb-40">ПОЧЕМУ ВАМ НУЖЕН НАШ СЕРВИС?</h2>
          <el-row
            v-for="(row, n) in blocks"
            :key="n"
            type="flex"
            justify="space-between"
          >
            <el-col
              v-for="(col, i) in row"
              :key="i"
              :xs="24"
              class="mb-50 descr-black fw-l pr-15"
            >
              <p class="m-0 fs-18">
                <b>{{ col.bold }}</b>
                {{ col.text }}
              </p>
            </el-col>
          </el-row>
        </el-col>
        <el-col :xs="24" :sm="6">
          <h2 class="t-blackDark mb-30">НАШИ ЗАКАЗЧИКИ:</h2>
          <div v-for="brand in brands" :key="brand" :class="[brand, 'mb-30']" />
        </el-col>
      </el-row>
    </section>
  </bg-image-wrapper>
</template>

<script>
import BgImageWrapper from '@/components/Wrappers/BgImageWrapper'
import { blocks, brands } from './constants'

export default {
  name: 'Description',
  components: { BgImageWrapper },
  data() {
    return {
      blocks,
      brands
    }
  }
}
</script>

<style lang="sass">
.descr-body
  .t-green_
    color: #14A14D
.descr-bottom
  .descr-black
    color: #303030
    max-width: 380px
    p
      line-height: 21px
  .cargill
    background: url('~@/assets/imgs/icons/cargill-icon.png') no-repeat
    width: 191px
    height: 61px
</style>
