<template>
  <section class="bg-image-wrapper">
    <el-row class="image-block">
      <el-image
        :src="image"
        :style="{ transform }"
        class="image"
        fit="cover"
        lazy
      />
      <div class="container">
        <div class="inherit">
          <h2 v-if="title" :class="['t-blackDark', mb0 ? 'mb-0' : 'mb-25']">
            {{ title }}
          </h2>
          <slot name="body" />
        </div>
        <callback-modal />
      </div>
    </el-row>
    <slot />
  </section>
</template>

<script>
import { mapMutations } from 'vuex'
import CallbackModal from '@/components/Modals/CallbackModal'

export default {
  name: 'PageWrapper',
  components: { CallbackModal },
  props: {
    src: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    mb0: {
      type: Boolean,
      default: false
    },
    flip: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    image() {
      return this.src ? require(`@/assets/imgs/${this.src}`) : undefined
    },
    transform() {
      return this.flip ? 'matrix(-1, 0, 0, 1, 0, 0)' : 'none'
    }
  },
  methods: {
    ...mapMutations({ setDialog: 'popups/setDialog' }),
    onClick() {
      this.setDialog({ name: 'callback-modal', visible: true })
    }
  }
}
</script>

<style lang="sass">
@import "~@/assets/styles/mixins.sass"

.bg-image-wrapper
  margin-bottom: 50px
  @include toH(700px)
    margin-bottom: 30px
  .image-block
    min-height: 600px
    position: relative
    @include toH(700px)
      min-height: 470px
    .image
      position: absolute
      width: 100%
      height: 100%
      z-index: -1
    .inherit
      max-width: 750px
      box-sizing: border-box
      margin: 70px 0
      padding: 40px 60px
      background: rgba(255, 255, 255, 0.9)
      @include toH(700px)
        padding: 25px 25px 25px 50px
        margin-top: 45px
      .t-black
        color: #2F2F2F
      .t-black-light
        color: #373737
      .i-btn
        position: relative
        .phone
          position: absolute
          background: url('~@/assets/imgs/icons/phone-icon.svg')
          width: 21px
          height: 21px
          left: 25px
          top: 14.5px
      .fc-btn
        margin-top: 50px
        @include toH(700px)
          margin-top: 35px
</style>
