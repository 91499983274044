export const settingsProductsFormRules = {
  full_name: {
    required: true,
    message: 'Необходимо заполнить наименование',
    trigger: 'blur'
  },
  name: {
    required: true,
    message: 'Необходимо заполнить короткое название',
    trigger: 'blur'
  }
}

export const settingsConsigneeFormRules = {
  name: {
    required: true,
    message: 'Необходимо заполнить короткое название',
    trigger: 'blur'
  }
}

export const callbackFormRules = {
  phone: [
    {
      required: true,
      message: 'Необходимо заполнить телефон',
      trigger: ['blur', 'change']
    },
    {
      min: 18,
      message: 'Телефон заполнен не полностью',
      trigger: ['blur']
    }
  ]
}
